import {
  all,
  fork,
  put,
  call,
  takeEvery,
  take,
  cancel,
} from 'redux-saga/effects';
import { actions } from '../reducers/global';
import { GLOBAL_DATA_KEY } from '../constants/globalConstants';
import { rsfDB } from '../firebase';

// call은 동기, fork는 비동기 요청
function* getGlobalData(action) {
  const task = yield fork(rsfDB.syncDocument, `global/${GLOBAL_DATA_KEY}`, {
    successActionCreator: (data) => ({
      type: actions.GET_GLOBAL_DATA_SUCCESS,
      data: data.data(),
    }),
    failureActionCreator: (err) => ({
      type: actions.GET_GLOBAL_DATA_FAILURE,
      err: err.message,
    }),
  });
  yield take(actions.SYNC_GLOBAL_DATA_DONE);
  yield cancel(task);
}
function* updateGlobalData({ target, value }) {
  try {
    yield call(
      rsfDB.updateDocument,
      `global/${GLOBAL_DATA_KEY}`,
      target,
      value
    );
    yield put({
      type: actions.UPDATE_GLOBAL_DATA_SUCCESS,
    });
  } catch (err) {
    yield put({
      type: actions.UPDATE_GLOBAL_DATA_FAILURE,
      error: err.message,
    });
  }
}

function* watchGetGlobalData() {
  yield takeEvery(actions.GET_GLOBAL_DATA_REQUEST, getGlobalData);
}
function* watchUpdateGlobalData() {
  yield takeEvery(actions.UPDATE_GLOBAL_DATA_REQUEST, updateGlobalData);
}
export default function* globalSaga() {
  yield all([fork(watchGetGlobalData), fork(watchUpdateGlobalData)]);
}
