import img01 from "../assets/central/introductionImage/1.png";
import img02 from "../assets/central/introductionImage/2.png";
import img03 from "../assets/central/introductionImage/3.png";
import img04 from "../assets/central/introductionImage/4.png";
export const GLOBAL_DATA_KEY = "AG9FarZB1rHQEB0lLNWC";
export const SITE_NAME = "부산직업교육온오프라인박람회";
export const introductionData = {
  title: "직업계고 학생들의 희망찬 미래를 응원합니다!",
  content: `청명한 하늘이 더없이 아름다운 가을을 맞이하여, ‘2022학년도 부산직업교육박람회’에 오신 부산 교육가족 및 시민 여러분, 진심으로 환영합니다. 
  
  2000년, 전국 최초로 개최한 부산직업교육박람회는 지금까지, 특성화 및 마이스터 고등학교 학생들에게 전문기술 능력 향상과 전문 기능인으로서의 자긍심을 높여주고, 초·중학생들에게는 내실 있는 진로 탐색과 직업체험의 기회를 제공하고 있습니다. 
  
  지난 2년 동안 코로나19로 인해 비대면으로 진행되었다가, 올해 다시 온·오프라인으로 확대 개최되는 이번 박람회는, 부산의 특성화고와 마이스터고, 그리고 부산국제영화고를 포함한 총 37개교 학생들이 참가하여 기‧예능전과 직업체험전, 특별관과 상담관 등의 다양한 체험 프로그램을 마련하였으며, 댄스공연, 난타 등, 26개 학교, 35개 동아리 학생들이 준비한 다채로운 공연도 함께 펼쳐질 예정입니다.
  
  첨단 기술이 눈부신 발전을 거듭하는 4차 산업혁명 시대에 숙련된 전문기술은 더 나은 미래를 만드는 원동력입니다. 
  
  직업계고 학생들의 열정이 맺은 결실과 전문 직업인의 꿈을 키우는 현장을 한눈에 체험할 수 있는 이번 박람회에 부산교육가족과 시민 여러분의 많은 관심과 성원을 부탁드립니다.
  
  우리 교육청은 지·산·학 연계의 직업교육 거버넌스 구축을 통해 직업계고 학생들의 전문성 향상과 선취업·후학습을 적극 지원하고 있습니다. 
  
  앞으로도, 직업계고 학생들이 부산의 미래 발전을 견인하는 글로벌 지역 인재로 성장할 수 있도록 최선을 다하겠습니다.
  
  깊어가는 가을, 직업계고 학생들의 꿈을 향한 열정이 담긴 이번 박람회와 함께, 유익하고 즐거운 시간을 보내시기를 바라며, 아울러 우리 학생들의 희망찬 미래를 응원합니다. 
  
  감사합니다. 
  
  `,

  date: "2022. 11. 7.",
  // position: '부산광역시',
  name: "부산광역시교육감 하 윤 수",
};

export const GUIDE_DATA = [
  {
    image: img01,
    title: "박람회 즐기는 방법",
    description: `‣ 회원가입하기
    ‣ 박람회 시청하며 댓글달기!
      ‣ 셋, 온라인 부스와 다양한 영상, VR 컨텐츠 살펴보기!
      ‣ 넷, 랭킹과 VR 등 다양한 이벤트 참여하기!`,
  },
  {
    image: img02,
    title: "온라인 부스",
    description:
      "부산에 있는 다양한 특성화 · 마이스터고등학교의 관한 영상과 사진 그리고 VR 컨텐츠는 물론 실시간 상담을 통해 진로와 직업에 대해 생각해보고 탐색해보세요!",
    link: "/booths",
  },
  {
    image: img03,
    title: "주제관",
    description:
      "다양한 학교에 대한 소개 영상과 공연 영상들을 확인할 수 있습니다. 진로와 관련된 다양한 영상들을 ‘주제관’ 메뉴에서 한 번에 시청해보세요!",
    link: "/videos",
  },
  {
    image: img04,
    title: "실시간 부스/상담",
    description: `실시간으로 소통하며 다양한 진로프로그램에 참여해보세요.
      ※ '실시간 부스/상담’ 메뉴에서 자세한 일정과 내용을 확인하세요!`,
    link: "/liveProgramPlan",
  },
];

export const tableSetLable = {
  body: {
    emptyDataSourceMessage: "관련 데이터가 없습니다.",
  },
  toolbar: {
    searchTooltip: "검색",
    searchPlaceholder: "검색",
  },
  pagination: {
    labelDisplayedRows: "현재: {from} - {to} / 전체: {count}",
    labelRowsSelect: "줄 씩 보기",
    firstAriaLabel: "첫 페이지",
    firstTooltip: "첫 페이지",
    previousAriaLabel: "이전 페이지",
    previousTooltip: "이전 페이지",
    nextAriaLabel: "다음 페이지",
    nextTooltip: "다음 페이지",
    lastAriaLabel: "마지막 페이지",
    lastTooltip: "마지막 페이지",
  },
};
// export const MENU_CONTENTS = [
//   {
//     label: 'HOME',
//     to: '/home',
//     icon: HomeRounded,
//     detail: '홈페이지로 이동!',
//   },
//   {
//     label: '참가부스',
//     to: '/booth',
//     icon: DirectionsRunRounded,
//     detail: '00개의 참가 부스를 한 눈에!',
//   },
//   {
//     label: '영상관',
//     to: '/videoContents',
//     icon: OndemandVideoRounded,
//     detail: '주제별 영상모음',
//   },
//   {
//     label: '실시간상담',
//     to: '/liveConsult',
//     icon: SupervisorAccountRounded,
//     detail: '실시간 상담 신청!',
//   },
//   {
//     label: '박람회소개',
//     to: '/introduction',
//     icon: DescriptionRounded,
//     detail: '부산 진로 온라인 박람회 정보',
//   },
//   {
//     label: '박람회 안내',
//     to: '/guide',
//     icon: HelpRounded,
//     detail: '부산 진로 온라인 박람회 안내',
//   },
// ];
