import React, { useState, useEffect, useCallback } from "react";
import Hidden from "@material-ui/core/Hidden";
import styled from "styled-components";
import MenuRoundedIcon from "@material-ui/icons/MenuRounded";

import NavLink from "../components/NavLink";
import {
  HomeRounded,
  DirectionsRunRounded,
  OndemandVideoRounded,
  SupervisorAccountRounded,
  DescriptionRounded,
  HelpRounded,
  StorefrontRounded,
  VpnKeyRounded,
} from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";

import RoundIcon from "../components/RoundIcon";
import SideMenu from "./SideMenu";
import { logoutRequestAction } from "../reducers/user";
const Container = styled.nav`
  display: flex;
  justify-content: space-between;
  width: calc(100% - 406px);

  align-items: center;
  padding-right: 20px;
  min-width: 60px;
  @media screen and (max-width: 500px) {
    padding-right: 0;
  }
`;
const COMMON_CONTENTS = [
  {
    label: "HOME",
    to: "/home",
    icon: HomeRounded,
    detail: "홈페이지로 이동!",
  },
  {
    label: `박람회소개`,
    to: "/introduction",
    icon: DescriptionRounded,
    detail: "박람회의 소개 및 교육감님 격려사 보기",
  },
  {
    label: "온라인부스",
    to: "/booths",
    icon: DirectionsRunRounded,
    detail: "박람회에 참여한 온라인 부스 보기",
  },
  {
    label: "주제관",
    to: "/videos",
    icon: OndemandVideoRounded,
    detail: "주제별로 구성된 영상 보기",
  },
  {
    label: "실시간부스",
    to: "/liveProgramPlan",
    icon: SupervisorAccountRounded,
    detail: "라이브 프로그램 신청 하기",
  },
  {
    label: "이용안내",
    to: "/guide",
    icon: HelpRounded,
    detail: "박람회의 안내사항 보기",
  },
];

const HeaderMenu = () => {
  const { isLoggedIn, userData } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [menuContents, setMenuContents] = useState([]);
  const [toggleDrawer, setToggleDrawer] = useState(false);

  const onClickHandler = useCallback(() => {
    setToggleDrawer((prev) => !prev);
  }, [toggleDrawer]);
  const logOutHandler = useCallback(() => {
    dispatch(logoutRequestAction());
  }, []);
  useEffect(() => {
    if (isLoggedIn) {
      if (userData.status === "student" || userData.status === "parent") {
        setMenuContents([
          ...COMMON_CONTENTS,
          {
            label: "마이페이지",
            to: "/myPage",
            icon: StorefrontRounded,
            detail: "내 정보 현황",
          },
        ]);
      } else if (userData.status === "public") {
        setMenuContents([
          ...COMMON_CONTENTS,
          {
            label: "로그아웃",
            onClick: logOutHandler,
            icon: StorefrontRounded,
            detail: "로그아웃 하기",
          },
        ]);
        // } else if (userData.status === 'admin') {
      } else if (userData.status === "admin") {
        setMenuContents([
          ...COMMON_CONTENTS,
          {
            label: "부스 관리하기",
            to: "/admin",
            icon: StorefrontRounded,
            detail: "부스 관리하기",
          },
        ]);
      } else if (userData.status === "superAdmin") {
        setMenuContents([
          ...COMMON_CONTENTS,
          {
            label: "슈퍼 관리자",
            to: "/superAdmin",
            icon: StorefrontRounded,
            detail: "슈퍼 관리자 입니다.",
          },
        ]);
      } else {
        // 유지보수 추가
        setMenuContents([
          ...COMMON_CONTENTS,
          {
            label: "로그인",
            to: "/login",
            icon: VpnKeyRounded,
            detail: "로그인 및 회원가입",
          },
        ]);
      }
    } else {
      setMenuContents([
        ...COMMON_CONTENTS,
        {
          label: "로그인",
          to: "/login",
          icon: VpnKeyRounded,
          detail: "로그인 및 회원가입",
        },
      ]);
    }
  }, [isLoggedIn]);
  return (
    <Container>
      <Hidden smDown>
        {menuContents.map(({ label, to, onClick }) => {
          return <NavLink key={label} to={to} text={label} onClick={onClick} />;
        })}
      </Hidden>
      <Hidden mdUp>
        <div></div>
        <RoundIcon onClick={onClickHandler}>
          <MenuRoundedIcon />
          <SideMenu toggleDrawer={toggleDrawer} menuContents={menuContents} />
        </RoundIcon>
      </Hidden>
    </Container>
  );
};

export default HeaderMenu;
