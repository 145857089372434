import React, { useEffect, lazy } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

const Index = lazy(() => import("../pages/Index"));
const Booths = lazy(() => import("../pages/Booths"));
const BoothsDetail = lazy(() => import("../pages/BoothsDetail"));
const VideoContents = lazy(() => import("../pages/VideoContents"));
const LiveProgramPlan = lazy(() => import("../pages/LiveProgramPlan"));
const LiveProgram = lazy(() => import("../pages/LiveProgram"));
const Ranking = lazy(() => import("../pages/Ranking"));
const RankingScreen = lazy(() => import("../pages/RankingScreen"));
const Introduction = lazy(() => import("../pages/Introduction"));
const Guide = lazy(() => import("../pages/Guide"));
const MyPage = lazy(() => import("../pages/MyPage"));
const SiteTerm = lazy(() => import("../pages/ServiceTerm"));
const ServiceTerm = lazy(() => import("../pages/SiteTerm"));
const Admin = lazy(() => import("../pages/Admin"));
const AdminLogin = lazy(() => import("../pages/AdminLogin"));
const SuperAdmin = lazy(() => import("../pages/SuperAdmin"));
const SuperAdminLogin = lazy(() => import("../pages/SuperAdminLogin"));
const Login = lazy(() => import("../pages/Login"));
const LoginWithStamp = lazy(() => import("../pages/LoginWithStamp"));
const Register = lazy(() => import("../pages/Register"));
const VideoDetail = lazy(() => import("../pages/VideoDetail"));
const AdminRegister = lazy(() => import("../pages/AdminRegister"));
const GdcommAdmin = lazy(() => import("../pages/GdcommAdmin"));
const Stamp = lazy(() => import("../pages/Stamp"));

import { checkUserSessionAction } from "../reducers/user";

const COMMON_ROUTES = [
  { path: "/home", component: Index },
  { path: "/ranking", component: Ranking },
  { path: "/rankingScreen", component: RankingScreen },
  { path: "/booth/:id", component: BoothsDetail },
  { path: "/booths", component: Booths },
  { path: "/video/:id", component: VideoDetail },
  { path: "/videos", component: VideoContents },
  { path: "/liveProgramPlan", component: LiveProgramPlan },
  { path: "/livePrograms", component: LiveProgram },
  { path: "/introduction", component: Introduction },
  { path: "/guide", component: Guide },
  { path: "/siteTerm", component: SiteTerm },
  { path: "/serviceTerm", component: ServiceTerm },
];
const commonRoutes = () => {
  return COMMON_ROUTES.map((e) => <Route path={e.path} component={e.component} key={e.path} />);
};
const StudentRoutes = () => {
  return (
    <Switch>
      <Redirect exact from="/" to="/home" />
      {commonRoutes()}
      <Route path="/stamp/:id" component={Stamp} />
      <Route path="/myPage" component={MyPage} />
      <Redirect from="/*" to="/home" />
    </Switch>
  );
};
const PublicRoutes = () => {
  return (
    <Switch>
      <Redirect exact from="/" to="/home" />
      {commonRoutes()}
      <Route path="/stamp/:id" component={Stamp} />
      <Redirect from="/*" to="/home" />
    </Switch>
  );
};
const AdminRoutes = () => {
  return (
    <Switch>
      <Redirect exact from="/" to="/home" />
      {commonRoutes()}
      <Route path="/stamp/:id" component={Stamp} />
      <Route path="/admin" component={Admin} />
      <Redirect from="/*" to="/home" />
    </Switch>
  );
};
const SuperAdminRoutes = () => {
  return (
    <Switch>
      <Redirect exact from="/" to="/home" />
      {commonRoutes()}
      <Route path="/stamp/:id" component={Stamp} />
      <Route path="/superAdmin" component={SuperAdmin} />
      <Route path="/admin/:id" component={Admin} />
      <Route path="/super-gdcomm-admin" component={GdcommAdmin} />
      <Redirect from="/*" to="/home" />
    </Switch>
  );
};

const LoggedOutRoutes = () => {
  return (
    <Switch>
      <Redirect exact from="/" to="/home" />
      {commonRoutes()}
      <Route path="/stamp/:id" component={LoginWithStamp} />
      <Route path="/login" component={Login} />
      <Route path="/register" component={Register} />
      <Route path="/adminRegister" component={AdminRegister} />
      <Route path="/admin" component={AdminLogin} />
      <Route path="/superAdmin" component={SuperAdminLogin} />
      <Route path="/super-gdcomm-admin" component={SuperAdminLogin} />
      <Redirect from="/*" to="/home" />
    </Switch>
  );
};
const AppRouter = () => {
  const { isLoggedIn, userData } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  useEffect(() => {
    const userId = window.sessionStorage.getItem("userId");
    if (userId) {
      dispatch(checkUserSessionAction());
    }
  }, []);
  if (isLoggedIn) {
    if (userData.status === "student") {
      return <StudentRoutes />;
    } else if (userData.status === "public") {
      return <PublicRoutes />;
    } else if (userData.status === "parent") {
      return <StudentRoutes />;
    } else if (userData.status === "admin") {
      return <AdminRoutes />;
    } else if (userData.status === "superAdmin") {
      return <SuperAdminRoutes />;
    }
  } else {
    return <LoggedOutRoutes />;
  }
};

export default AppRouter;
