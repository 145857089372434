import firebase from "firebase";
import "@firebase/firestore";
import "@firebase/analytics";
import ReduxSagaFirebase from "redux-saga-firebase";
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA-ofHQCgIPdmwHZj3m6sUKuzsA9VKMoWE",
  authDomain: "bsjobedu2022.firebaseapp.com",
  projectId: "bsjobedu2022",
  storageBucket: "bsjobedu2022.appspot.com",
  messagingSenderId: "268081622480",
  appId: "1:268081622480:web:250d8aec14690888971dd5",
  measurementId: "G-9GSJ166QZH",
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const rsf = new ReduxSagaFirebase(firebaseApp);
export const db = firebaseApp.firestore();
export const rsfDB = rsf.firestore;
export const storage = firebaseApp.storage();
export const auth = rsf.auth;
export const firestore = firebase.firestore;
firebase.analytics();
